import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@mui/material/styles';
import red from '@mui/material/colors/red';
import HungHau from '../fonts/HungHau-Regular.ttf'
// Create a theme instance.
export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
  typography:{
    fontFamily: 'HungHau'
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'HungHau';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('HungHau'), local('HungHau-Regular'), url(${HungHau}) format('truetype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
  },

});