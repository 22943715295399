export const GET_ERRORS = "GET_ERRORS"
export const CLEAR_ERRORS = "CLEAR_ERRORS"

export const SET_AUTH_INFO = "SET_AUTH_INFO"
export const SET_CONTROL_THEMVANBAN = "SET_CONTROL_THEMVANBAN"
export const SET_SNACK_MESSAGE = "SET_SNACK_MESSAGE"
export const GET_VANBAN = "GET_VANBAN"
export const SET_CONTROL_EDITVANBAN = "SET_CONTROL_EDITVANBAN"
export const SET_PAGE_SIZE = "SET_PAGE_SIZE"
export const SET_DELETE_CONTROL ="SET_DELETE_CONTROL"
export const GET_VANBAN_ITEM = "GET_VANBAN_ITEM"

export const SET_AUTH_TOKEN = "SET_AUTH_TOKEN"
export const SET_REFESH_TOKEN = "SET_REFESH_TOKEN"

export const SET_VANBAN_DETAILS = "SET_VANBAN_DETAILS"
export const SET_UPDATE_VANBAN_LQ = "SET_UPDATE_VANBAN_LQ"
export const SET_IMPORT_RESULTS = "SET_IMPORT_RESULTS"
export const SET_IMPORT_STATUS = "SET_IMPORT_STATUS"

export const UPDATE_VANBAN_ITEM = "UPDATE_VANBAN_ITEM"