import { SET_AUTH_INFO,SET_AUTH_TOKEN,SET_REFESH_TOKEN
 } from '../../utils/types';

const initialState = {
  userInfo:null,
  isAdmin:false,
  accessToken:null,
  expiresOn:null
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action) {
  switch (action.type) {
    case SET_AUTH_INFO:
      return {
        ...state,
        isAdmin:action.payload.Role === 1,
        userInfo:action.payload
      };
    case SET_AUTH_TOKEN:
      return{
        ...state,
        accessToken:action.payload.accessToken,
        expiresOn:action.payload.expiresOn
      }
    default:
      return state;
  }
}
