import React from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import { ThemeProvider } from "@mui/material/styles";
import {CssBaseline} from '@mui/material'
import { theme } from "./styles/theme";
import { CustomNavigationClient } from "./utils/NavigationClient";
import DateAdapter from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Provider } from 'react-redux';
import store from './state/store';
import dayjs from 'dayjs'
import 'dayjs/locale/vi'
dayjs.locale('vi')
const msalInstance = new PublicClientApplication(msalConfig);

export default ({ element }) => {
  // The next 2 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
  const navigationClient = new CustomNavigationClient();
  msalInstance.setNavigationClient(navigationClient);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={DateAdapter}>
          <CssBaseline/>
          <MsalProvider instance={msalInstance}>
            {element}
          </MsalProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </Provider>
  );
};
