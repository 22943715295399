import { SET_CONTROL_THEMVANBAN,GET_VANBAN,SET_PAGE_SIZE,
  SET_DELETE_CONTROL,GET_VANBAN_ITEM,SET_SNACK_MESSAGE,
  SET_VANBAN_DETAILS,SET_UPDATE_VANBAN_LQ,SET_IMPORT_RESULTS,
  UPDATE_VANBAN_ITEM
 } from '../../utils/types';

const initialState = {
  LinhVuc:[],
  NoiBanHanh:[],
  vanBanItem:null,
  vanBan:[],
  vanBanDetails:[],
  isImport:false,
  imports:{
    status:0,
    range:0,
    failed:null,
    isCompleted:true
  },
  pageSize:10,
  deleteID:'',
  snackMessage:null,
  isAdmin:false,
  isThemVanBan:false
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action) {
  switch (action.type) {
    case SET_CONTROL_THEMVANBAN:
      return{
        ...state,
        isThemVanBan:!state.isThemVanBan
      }
    case SET_VANBAN_DETAILS:
      return{
        ...state,
        vanBanDetails:action.payload
      }
    case SET_SNACK_MESSAGE:
      return{
        ...state,
        snackMessage:action.payload
      }
    case GET_VANBAN:
      return{
        ...state,
        vanBan:action.payload
      }
    case GET_VANBAN_ITEM:
      return{
        ...state,
        vanBanItem:action.payload
      }
    case SET_PAGE_SIZE:
      return{
        ...state,
        pageSize:action.payload
      }
    case SET_DELETE_CONTROL:
      return{
        ...state,
        deleteID:action.payload
      }
    case SET_IMPORT_RESULTS:
      return{
        ...state,
        imports:action.payload
      }
    case UPDATE_VANBAN_ITEM:
      return{
        ...state,
        vanBanItem:{
          ...state.vanBanItem,
          ...action.payload
        }
      }
    default:
      return state;
  }
}
